export function nonReactiveClone(object: unknown) {
  return JSON.parse(JSON.stringify(object));
}

export function fileToSizeString(bytes: number) {
  if (bytes < 1000) return `${bytes} bytes`;
  if (bytes < 1000000) return `${(bytes / 1000).toFixed(2)} kb`;
  if (bytes < 1000000000) return `${(bytes / 1000000).toFixed(2)} mb`;
  if (bytes < 1000000000000) return `${(bytes / 1000000000).toFixed(2)} gb`;
  return `${(bytes / 1000000000000).toFixed(2)} tb`;
}

export function fileToUrl(file: File) {
  return URL.createObjectURL(file);
}

export function isUUIDv4(str: string): boolean {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(str);
}

export const DateTimeFormatString = "YYYY-MM-DD HH:mm:ss";

export function toRelativeUrl(url: string): string {
  const urlObject = new URL(url);
  return urlObject.pathname + urlObject.search;
}

type GenericObject = { [key: string]: unknown };

export function getFirstDefinedField<T extends GenericObject, K extends keyof T>(
  array: T[],
  field: K
): T[K] | undefined {
  const foundObject = array.find((item) => item[field] !== undefined);
  return foundObject ? foundObject[field] : undefined;
}
export { default as clsx } from "clsx";

export function findObjectIn2DimArray<Data>(
  array: Data[][],
  key: keyof Data,
  value: unknown
): [number, number] | undefined {
  for (let rowIndex = 0; rowIndex < array.length; rowIndex++) {
    const row = array[rowIndex];
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      const item = row[colIndex];
      if (item[key] === value) {
        return [rowIndex, colIndex];
      }
    }
  }
  return undefined;
}

export function toPlainClone(object: unknown) {
  if (!object) return undefined;
  return JSON.parse(JSON.stringify(object));
}
