export enum ICONS {
  submitted = "lucide:mail-question",
  accepted = "lucide:wrench",
  accept = "lucide:thumbs-up",
  block = "lucide:eye-off",
  observed = "lucide:telescope",
  discarded = "lucide:square-x",
  spam = "lucide:archive-x",
  rejected = "lucide:thumbs-down",
  checked = "lucide:circle-check-big",
  published = "lucide:circle-check",

  bookmark = "lucide:bookmark",
  new = "lucide:eye",

  home = "lucide:home",
  forum = "lucide:message-square",
  handbook = "lucide:notebook-text",
  submissions = "lucide:inbox",
  claims = "lucide:target",

  add = "lucide:plus-circle",
  delete = "lucide:trash-2",
  view = "lucide:scan-eye",
  link = "lucide:external-link",
  zoom = "lucide:zoom-in",
  confirm = "lucide:check",
  cancel = "lucide:x",
  loader = "lucide:loader-circle",

  user = "lucide:user",
  users = "lucide:users",
  settings = "lucide:settings",

  community = "lucide:pen-tool",

  up = "lucide:chevron-up",
  down = "lucide:chevron-down",

  logout = "lucide:log-out",

  channels = "lucide:megaphone",
  dms = "lucide:message-square-text",
  upload = "lucide:upload"
}

export enum ALL_CLAIM_STATUSES {
  submitted = "submitted",
  accepted = "accepted",
  observed = "observed",
  discarded = "discarded",
  spam = "spam",
  rejected = "rejected",
  checked = "checked",
  published = "published"
}

export const SUBMISSION_STATUSES = [
  ALL_CLAIM_STATUSES.submitted,
  ALL_CLAIM_STATUSES.observed,
  ALL_CLAIM_STATUSES.spam,
  ALL_CLAIM_STATUSES.rejected
];

export const enum QUERY_KEYS {
  allUsersWithRoles = "allUsersWithRoles",
  claim = "claim",
  claims = "claims",
  channels = "channels",
  comments = "comments",
  comment = "comment",
  user = "user",
  users = "users",
  usersPaginated = "usersPaginated",
  userAccountView = "userAccountView",
  submissions = "submissions",
  submission = "submission",
  categories = "categories",
  categoriesPaginated = "categoriesPaginated",
  channelParticipants = "channelParticipants",
  ratingLabels = "ratingLabels",
  ratingLabelsPaginated = "ratingLabelsPaginated",
  handbookSections = "handbookSections"
}
export const enum MUTATION_KEYS {
  userInfoUpdate = "userInfoUpdate",
  categoryDelete = "categoryDelete",
  categoryInsert = "categoryInsert",
  claimCategoryDelete = "claimCategoryDelete",
  claimCategoryInsert = "claimCategoryInsert",
  claimOriginDelete = "claimOriginDelete",
  claimOriginInsert = "claimOriginInsert",
  claimSynopsisUpdate = "claimSynopsisUpdate",
  claimStatusUpdate = "claimStatusUpdate",
  claimInternalUpdate = "claimInternalUpdate",
  claimSourceDelete = "claimSourceDelete",
  claimSourceInsert = "claimSourceInsert",
  claimFactDelete = "claimFactDelete",
  claimFactInsert = "claimFactInsert",
  commentInsert = "commentInsert",
  userRoleUpdate = "userRoleUpdate",
  claimDelete = "claimDelete",
  submissionInsert = "submissionInsert",
  claimSubmitterNotesUpdate = "claimSubmitterNotesUpdate",
  factInsert = "factInsert",
  ratingLabelDelete = "ratingLabelDelete",
  ratingLabelInsert = "ratingLabelInsert",
  claimStatusInsert = "claimStatusInsert",
  claimStatusHasMarkedUpdate = "claimStatusHasMarkedUpdate",
  claimStatusHasReadUpdate = "claimStatusHasReadUpdate",
  commentUserReactionInsert = "commentUserReactionInsert",
  commentUserReactionToggle = "commentUserReactionToggle",
  commentUpdateContent = "commentUpdateContent",
  commentSoftDelete = "commentSoftDelete",
  commentSetBlock = "commentSetBlock",
  originUpdate = "originUpdate",
  sourceUpdate = "sourceUpdate",
  handbookSectionTeaserUpdate = "handbookSectionTeaserUpdate"
}

export enum UserRole {
  Aspirant = "aspirant",
  Junior = "junior",
  Senior = "senior",
  Moderator = "moderator",
  Editor = "editor",
  Administrator = "administrator"
}

export const USER_ROLE_POWERLEVELS = {
  [UserRole.Administrator]: 100,
  [UserRole.Editor]: 80,
  [UserRole.Moderator]: 60,
  [UserRole.Senior]: 40,
  [UserRole.Junior]: 20,
  [UserRole.Aspirant]: 10
};

export const enum TABLES {
  claim = "claim",
  comment = "comment",
  fact = "fact",
  event = "event",
  file = "file",
  origin = "origin",
  source = "source",
  user = "user",
  category = "category",
  rating_label = "rating_label",
  claim_category = "claim_category",
  comment_user_reactions = "comment_user_reactions"
}
