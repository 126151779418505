<script setup>
import { TooltipProvider } from "radix-vue";
import "vue3-emoji-picker/css";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.min.css";

import "@/assets/css/fonts.css";

const route = useRoute();
const { t } = useI18n();
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true
});
const title = computed(() => (route.meta.title ? t(route.meta.title) : "Faktenforum"));
const {
  public: { nodeEnv }
} = useRuntimeConfig();
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <NuxtLoadingIndicator />
      <TooltipProvider :delay-duration="0">
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
        <BreakpointInspector v-if="nodeEnv === 'development'" />
        <Toaster />
      </TooltipProvider>
      <Lightbox />
      <ViewportSizeWarning />
    </Body>
  </Html>
</template>
