export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    getUserAccountView: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserAccountView {
  userAccountView {
    bio
    email
    createdAt
    firstName
    id
    lastName
    mobileNumber
    profileImage
    pronouns
    updatedAt
    username
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserAccountView', 'query'),
    getCategories: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCategories {
  categories: category {
    name
    labelEn
    labelDe
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCategories', 'query'),
    getCategoriesPaginated: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCategoriesPaginated($offset: Int!, $limit: Int!) {
  category(offset: $offset, limit: $limit, orderBy: { name: ASC }) {
    name
    labelEn
    labelDe
    claimCategoriesAggregate {
      aggregate {
        count
      }
    }
  }
  categoryAggregate {
    aggregate {
      count
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCategoriesPaginated', 'query'),
    deleteCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteCategory($name: String!) {
  deleteCategory(where: { name: { _eq: $name } }) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCategory', 'query'),
    insertCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertCategory($name: String!, $labelEn: String!, $labelDe: String!) {
    insertCategory(objects: [{ name: $name, labelEn: $labelEn, labelDe: $labelDe }]) {
        returning {
            name
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertCategory', 'query'),
    insertClaimCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertClaimCategory($claimId: uuid!, $categoryName: String!) {
  insertClaimCategoryOne(object: { categoryName: $categoryName, claimId: $claimId }) {
    id
    categoryName
    claimId
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertClaimCategory', 'query'),
    deleteClaimCategory: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteClaimCategory($claimId: uuid!, $categoryName: String!) {
  deleteClaimCategory(where: { _and: { categoryName: { _eq: $categoryName }, claimId: { _eq: $claimId } } }) {
    returning {
      id
      categoryName
      claimId
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteClaimCategory', 'query'),
    getChannels: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getChannels {
  data: channel(orderBy: { name: ASC }) {
    descriptionDe
    descriptionEn
    archived
    fileId
    internal
    labelEn
    labelDe
    name
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChannels', 'query'),
    deleteClaimById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteClaimById($id: uuid = "") {
  deleteClaimByPk(id: $id) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteClaimById', 'query'),
    deleteOriginById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteOriginById($id: uuid = "") {
  deleteOriginByPk(id: $id) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteOriginById', 'query'),
    filterClaims: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query filterClaims(
  $limit: Int = 10
  $offset: Int = 0
  $search: String = "%%"
  $claimStatus: [claim_status!] = ["accepted"]
  $filter: [ClaimBoolExp!] = { _and: [] }
) {
  data: claim(
    limit: $limit
    offset: $offset
    orderBy: { createdAt: DESC }
    where: { _and: [{ synopsis: { _ilike: $search } }, { status: { _in: $claimStatus } }, { _and: $filter }] }
  ) {
    id
    status
    synopsis
    processId
    internal
    claimCategories {
      id
      categoryName
      category {
        labelDe
        labelEn
      }
    }
    userClaimStatuses {
      hasMarked
      hasRead
    }
    createdAt
    createdByUser {
      id
      username
    }
    lastUpdate: events(limit: 1, where: { tableName: { _neq: "comment" } }, orderBy: { createdAt: DESC }) {
      user {
        username
        id
      }
      date: createdAt
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'filterClaims', 'query'),
    getClaimById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getClaimById($id: uuid!) {
  claim: claimByPk(id: $id) {
    id
    status
    submitterNotes
    shortId
    processId
    synopsis
    ratingLabelName
    ratingStatement
    ratingSummary
    createdAt
    internal
    createdByUser {
      id
      username
    }
    claimCategories {
      id
      categoryName
      category {
        labelDe
        labelEn
      }
    }
    origins(orderBy: { index: ASC }) {
      id
      claimId
      index
      excerpt
      archiveUrl
      url
      remarks
      file {
        mimeType
        id
        transcription
        name
        eTag
      }
    }
    facts(orderBy: { index: ASC }) {
      id
      index
      text
      sources(orderBy: { index: ASC }) {
        id
        index
        excerpt
        archiveUrl
        url
        remarks
        file {
          mimeType
          id
          transcription
          name
          eTag
        }
      }
    }
    userClaimStatuses {
      hasMarked
      hasRead
    }
    lastUpdate: events(where: { tableName: { _neq: "comment" } }, orderBy: { createdAt: DESC }, limit: 1) {
      date: createdAt
      user {
        id
        username
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getClaimById', 'query'),
    insertClaimOrigin: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertClaimOrigin(
  $claimId: uuid = ""
  $url: String = null
  $fileId: uuid = null
  $archiveUrl: String = null
  $excerpt: String = null
  $remarks: String = null
) {
  data: insertOriginOne(
    object: {
      archiveUrl: $archiveUrl
      excerpt: $excerpt
      fileId: $fileId
      url: $url
      claimId: $claimId
      remarks: $remarks
    }
  ) {
    id
    claimId
    index
    url
    archiveUrl
    excerpt
    remarks
    file {
      id
      mimeType
      name
      eTag
      transcription
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertClaimOrigin', 'query'),
    updateClaimInternal: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimInternal($id: uuid = "", $internal: Boolean!) {
  updateClaimByPk(pkColumns: { id: $id }, _set: { internal: $internal }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimInternal', 'query'),
    updateOriginUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginUrl($id: uuid!, $url: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { url: $url }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginUrl', 'query'),
    updateOriginArchiveUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginArchiveUrl($id: uuid!, $archiveUrl: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { archiveUrl: $archiveUrl }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginArchiveUrl', 'query'),
    updateOriginExcerpt: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginExcerpt($id: uuid!, $excerpt: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { excerpt: $excerpt }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginExcerpt', 'query'),
    updateOriginRemarks: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginRemarks($id: uuid!, $remarks: String!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { remarks: $remarks }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginRemarks', 'query'),
    updateSourceFileIdToNull: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceFileIdToNull($id: uuid!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { fileId: null }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceFileIdToNull', 'query'),
    updateClaimRatingLabelName: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimRatingLabelName($id: uuid = "", $ratingLabelName: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { ratingLabelName: $ratingLabelName }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimRatingLabelName', 'query'),
    updateClaimRatingStatement: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimRatingStatement($id: uuid = "", $ratingStatement: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { ratingStatement: $ratingStatement }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimRatingStatement', 'query'),
    updateClaimRatingSummary: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimRatingSummary($id: uuid = "", $ratingSummary: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { ratingSummary: $ratingSummary }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimRatingSummary', 'query'),
    updateClaimSubmitterNotes: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimSubmitterNotes($id: uuid = "", $submitterNotes: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { submitterNotes: $submitterNotes }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimSubmitterNotes', 'query'),
    updateClaimSynopsis: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateClaimSynopsis($id: uuid = "", $synopsis: String = "") {
  updateClaimByPk(pkColumns: { id: $id }, _set: { synopsis: $synopsis }) {
    id
    synopsis
    submitterNotes
    status
    shortId
    ratingLabelName
    ratingStatement
    ratingSummary
    updatedAt
    createdAt
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateClaimSynopsis', 'query'),
    insertCommentUserReaction: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertCommentUserReaction($commentId: uuid!, $emoji: String!) {
  insertCommentUserReactionsOne(object: { commentId: $commentId, emoji: $emoji }) {
    commentId
    emoji
    id
    userId
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertCommentUserReaction', 'query'),
    getCommentUserReaction: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCommentUserReaction($userId: uuid = "", $emoji: String = "", $commentId: uuid = "") {
  commentUserReactions(
    where: { userId: { _eq: $userId }, emoji: { _eq: $emoji }, commentId: { _eq: $commentId } }
  ) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCommentUserReaction', 'query'),
    deleteCommentUserReaction: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteCommentUserReaction($id: uuid!) {
  deleteCommentUserReactionsByPk(id: $id) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteCommentUserReaction', 'query'),
    getCommentById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCommentById($id: uuid = "") {
  commentByPk(id: $id) {
    id
    claimId
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
    threadAggregate {
      aggregate {
        count
      }
    }
    userReactions {
      id
      emoji
      createdAt
      user {
        id
        username
        profileImage
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCommentById', 'query'),
    getCommentsByClaimId: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCommentsByClaimId($claimId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  comments: comment(
    where: { claimId: { _eq: $claimId }, threadId: { _isNull: true } }
    limit: $limit
    offset: $offset
    orderBy: { createdAt: DESC }
  ) {
    id
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
    threadAggregate {
      aggregate {
        count
      }
    }
    userReactions {
      id
      emoji
      createdAt
      user {
        id
        profileImage
        username
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCommentsByClaimId', 'query'),
    getCommentsByThreadId: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getCommentsByThreadId($threadId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  comments: comment(
    where: { threadId: { _eq: $threadId } }
    limit: $limit
    offset: $offset
    orderBy: { createdAt: DESC }
  ) {
    id
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
    userReactions {
      id
      emoji
      createdAt
      user {
        id
        profileImage
        username
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCommentsByThreadId', 'query'),
    getChannelParticipants: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getChannelParticipants($claimId: uuid = "") {
  comment(distinctOn: createdBy, where: { claimId: { _eq: $claimId } }) {
    createdByUser {
      id
      username
      profileImage
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChannelParticipants', 'query'),
    insertComment: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertComment($claimId: uuid!, $content: String!) {
  insertCommentOne(object: { claimId: $claimId, content: $content }) {
    id
    claimId
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertComment', 'query'),
    insertCommentInThread: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertCommentInThread($claimId: uuid!, $threadId: uuid!, $content: String!) {
  insertCommentOne(object: { claimId: $claimId, threadId: $threadId, content: $content }) {
    id
    claimId
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertCommentInThread', 'query'),
    updateCommentBlocked: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateCommentBlocked($id: uuid!, $blocked: Boolean!) {
  updateCommentByPk(pkColumns: { id: $id }, _set: { blocked: $blocked }) {
    id
    claimId
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCommentBlocked', 'query'),
    updateCommentContent: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateCommentContent($id: uuid!, $content: String!) {
  updateCommentByPk(pkColumns: { id: $id }, _set: { content: $content }) {
    id
    claimId
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCommentContent', 'query'),
    updateCommentDeleted: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateCommentDeleted($id: uuid!) {
  updateCommentByPk(pkColumns: { id: $id }, _set: { deleted: true }) {
    id
    claimId
    content: displayedContent
    deleted
    blocked
    createdAt
    updatedAt
    createdByUser {
      id
      username
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCommentDeleted', 'query'),
    getLastEventId: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getLastEventId($limit: Int = 10) {
  event(orderBy: { id: DESC }, limit: $limit) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLastEventId', 'query'),
    subscribeEvents: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`subscription subscribeEvents($id: bigint = 0, $batchSize: Int = 10) {
  eventStream(batchSize: $batchSize, cursor: { initialValue: { id: $id } }) {
    action
    claimId
    claimStatus
    createdAt
    entryId
    id
    tableName
    user {
      id
      username
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'subscribeEvents', 'query'),
    deleteFactById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteFactById($id: uuid!) {
  deleteFactByPk(id: $id) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteFactById', 'query'),
    insertFact: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertFact($claimId: uuid!, $text: String = null) {
  data: insertFactOne(object: { claimId: $claimId, text: $text }) {
    id
    index
    text
    claimId
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertFact', 'query'),
    updateFactTextById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateFactTextById($id: uuid!, $text: String!) {
  updateFactByPk(pkColumns: { id: $id }, _set: { text: $text }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateFactTextById', 'query'),
    GetHandbookSections: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetHandbookSections {
  handbookSections(orderBy: { titleDe: ASC }) {
    id
    titleDe
    titleEn
    teaserImage
    teaserTextDe
    teaserTextEn
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHandbookSections', 'query'),
    GetHandbookSectionById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetHandbookSectionById($id: uuid!) {
  handbookSections(where: { id: { _eq: $id } }) {
    contentDe
    contentEn
    titleDe
    titleEn
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHandbookSectionById', 'query'),
    UpdateHandbookSectionTeaser: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation UpdateHandbookSectionTeaser(
  $id: uuid!
  $titleDe: String
  $titleEn: String
  $teaserTextDe: String
  $teaserTextEn: String
) {
  updateHandbookSections(
    where: { id: { _eq: $id } }
    _set: { teaserTextDe: $teaserTextDe, teaserTextEn: $teaserTextEn, titleDe: $titleDe, titleEn: $titleEn }
  ) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateHandbookSectionTeaser', 'query'),
    UpdateHandbookSectionContent: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation UpdateHandbookSectionContent($id: uuid!, $contentDe: String, $contentEn: String) {
  updateHandbookSections(
    where: { id: { _eq: $id } }
    _set: { contentDe: $contentDe, contentEn: $contentEn }
  ) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateHandbookSectionContent', 'query'),
    InsertHandbookSection: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation InsertHandbookSection(
  $titleDe: String!
  $titleEn: String!
  $teaserTextDe: String!
  $teaserTextEn: String!
  $contentDe: String!
  $contentEn: String!
) {
  insertHandbookSections(
    objects: {
      contentDe: $contentDe
      contentEn: $contentEn
      teaserTextDe: $teaserTextDe
      teaserTextEn: $teaserTextEn
      titleDe: $titleDe
      titleEn: $titleEn
    }
  ) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InsertHandbookSection', 'query'),
    DeleteHandbookSection: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation DeleteHandbookSection($id: uuid!) {
  deleteHandbookSections(where: { id: { _eq: $id } }) {
    returning {
      id
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteHandbookSection', 'query'),
    getRatingLabels: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getRatingLabels {
  ratingLabels: ratingLabel {
    labelDe
    labelEn
    name
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRatingLabels', 'query'),
    getRatingLabelsPaginated: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getRatingLabelsPaginated($offset: Int!, $limit: Int!) {
  ratingLabel(offset: $offset, limit: $limit, orderBy: { name: ASC }) {
    labelDe
    labelEn
    name
    claimsAggregate {
      aggregate {
        count
      }
    }
  }
  ratingLabelAggregate {
    aggregate {
      count
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRatingLabelsPaginated', 'query'),
    deleteRatingLabel: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteRatingLabel($name: String!) {
  deleteRatingLabel(where: { name: { _eq: $name } }) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteRatingLabel', 'query'),
    insertRatingLabel: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertRatingLabel($labelDe: String!, $labelEn: String!, $name: String!) {
  insertRatingLabel(objects: [{ labelDe: $labelDe, labelEn: $labelEn, name: $name }]) {
    returning {
      name
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertRatingLabel', 'query'),
    insertSourceByFactId: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertSourceByFactId(
  $factId: uuid!
  $fileId: uuid = null
  $url: String = null
  $excerpt: String = null
  $archiveUrl: String = null
  $remarks: String = null
) {
  insertSourceOne(
    object: {
      factId: $factId
      fileId: $fileId
      url: $url
      excerpt: $excerpt
      archiveUrl: $archiveUrl
      remarks: $remarks
    }
  ) {
    id
    index
    factId
    url
    archiveUrl
    excerpt
    remarks
    file {
      id
      mimeType
      name
      eTag
      transcription
    }
    fact {
      claimId
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertSourceByFactId', 'query'),
    deleteSourceById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation deleteSourceById($id: uuid!) {
  deleteSourceByPk(id: $id) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteSourceById', 'query'),
    updateSourceUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceUrl($id: uuid!, $url: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { url: $url }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceUrl', 'query'),
    updateSourceArchiveUrl: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceArchiveUrl($id: uuid!, $archiveUrl: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { archiveUrl: $archiveUrl }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceArchiveUrl', 'query'),
    updateSourceExcerpt: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceExcerpt($id: uuid!, $excerpt: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { excerpt: $excerpt }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceExcerpt', 'query'),
    updateSourceRemarks: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSourceRemarks($id: uuid!, $remarks: String!) {
  updateSourceByPk(pkColumns: { id: $id }, _set: { remarks: $remarks }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSourceRemarks', 'query'),
    updateOriginFileIdToNull: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateOriginFileIdToNull($id: uuid!) {
  updateOriginByPk(pkColumns: { id: $id }, _set: { fileId: null }) {
    claimId
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOriginFileIdToNull', 'query'),
    filterSubmission: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query filterSubmission(
  $limit: Int = 10
  $offset: Int = 0
  $search: String = "%%"
  $claimStatus: claim_status = "submitted"
  $filter: [ClaimBoolExp!] = { _and: [] }
) {
  data: claim(
    limit: $limit
    offset: $offset
    where: {
      _and: [
        {
          _or: [
            { submitterNotes: { _ilike: $search } }
            { origins: { excerpt: { _ilike: $search } } }
            { origins: { url: { _ilike: $search } } }
          ]
        }
        { status: { _eq: $claimStatus } }
        { _and: $filter }
      ]
    }
    orderBy: { createdAt: DESC }
  ) {
    id
    shortId
    submitterNotes
    internal
    createdAt
    createdByUser {
      id
      username
    }
    status
    lastUpdate: events(where: { tableName: { _neq: "comment" } }, orderBy: { createdAt: DESC }, limit: 1) {
      date: createdAt
      user {
        id
        username
      }
    }
    origins: origins {
      id
      excerpt
      url
      archiveUrl
      remarks
      file {
        id
        mimeType
        name
        size
        transcription
      }
      updatedAt
      index
    }
    ratingStatement
    ratingSummary
    ratingLabelName
    synopsis
    userClaimStatuses {
      hasMarked
      hasRead
    }
    claimCategories {
      id
      categoryName
      category {
        labelDe
        labelEn
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'filterSubmission', 'query'),
    getSubmissionById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getSubmissionById($id: uuid!) {
  submission: claimByPk(id: $id) {
    origins(orderBy: { index: ASC }) {
      id
      claimId
      index
      excerpt
      archiveUrl
      url
      remarks
      file {
        mimeType
        id
        transcription
        name
        eTag
      }
    }
    facts(orderBy: { index: ASC }) {
      id
      index
      text
      sources(orderBy: { index: ASC }) {
        id
        index
        excerpt
        archiveUrl
        url
        remarks
        file {
          mimeType
          id
          transcription
          name
          eTag
        }
      }
    }
    id
    status
    submitterNotes
    shortId
    internal
    synopsis
    userClaimStatuses {
      hasMarked
      hasRead
    }
    createdAt
    createdByUser {
      id
      username
    }
    updatedAt
    updatedByUser {
      id
      username
    }
    claimCategories {
      id
      categoryName
      category {
        labelDe
        labelEn
      }
    }
    lastUpdate: events(where: { tableName: { _neq: "comment" } }, orderBy: { createdAt: DESC }, limit: 1) {
      date: createdAt
      user {
        id
        username
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSubmissionById', 'query'),
    insertSubmissionOne: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertSubmissionOne {
  insertClaimOne(object: {}) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertSubmissionOne', 'query'),
    updateSubmissionStatus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateSubmissionStatus($id: uuid!, $status: claim_status!) {
  updateClaimByPk(pkColumns: { id: $id }, _set: { status: $status }) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateSubmissionStatus', 'query'),
    getUserById: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserById($id: uuid!) {
  userByPk(id: $id) {
    bio
    createdAt
    id
    profileImage
    pronouns
    updatedAt
    username
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserById', 'query'),
    getUserRole: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUserRole($ids: [uuid]!) {
  userRole(ids: $ids) {
    id
    role
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserRole', 'query'),
    getUsers: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUsers {
  user {
    email
    createdAt
    firstName
    lastName
    mobileNumber
    profileImage
    pronouns
    updatedAt
    username
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUsers', 'query'),
    getUsersPaginated: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query getUsersPaginated($offset: Int!, $limit: Int!, $username: String, $email: String) {
  user(
    offset: $offset
    limit: $limit
    orderBy: { username: ASC }
    where: { _or: [{ username: { _ilike: $username } }, { email: { _ilike: $email } }] }
  ) {
    id
    email
    username
  }
  userAggregate(where: { _or: [{ username: { _ilike: $username } }, { email: { _ilike: $email } }] }) {
    aggregate {
      count
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUsersPaginated', 'query'),
    updateUserInfo: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserInfo(
  $id: uuid!
  $bio: String
  $firstName: String!
  $lastName: String!
  $pronouns: String
) {
  updateUserByPk(
    pkColumns: { id: $id }
    _set: { firstName: $firstName, lastName: $lastName, pronouns: $pronouns, bio: $bio }
  ) {
    id
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserInfo', 'query'),
    updateUserRole: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserRole($userId: uuid!, $role: UserRole!) {
    updateUserRole(userId: $userId, role: $role) {
        id
        role
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserRole', 'query'),
    insertUserClaimStatusOne: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation insertUserClaimStatusOne($claimId: uuid!, $hasRead: Boolean!, $hasMarked: Boolean!) {
  insertUserClaimStatusOne(object: { claimId: $claimId, hasRead: $hasRead, hasMarked: $hasMarked }) {
    hasRead
    hasMarked
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insertUserClaimStatusOne', 'query'),
    updateUserClaimStatusByPkHasRead: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserClaimStatusByPkHasRead($userId: uuid!, $claimId: uuid!, $hasRead: Boolean!) {
  updateUserClaimStatusByPk(pkColumns: { claimId: $claimId, userId: $userId }, _set: { hasRead: $hasRead }) {
    hasRead
    hasMarked
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserClaimStatusByPkHasRead', 'query'),
    updateUserClaimStatusByPkHasMarked: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation updateUserClaimStatusByPkHasMarked($userId: uuid!, $claimId: uuid!, $hasMarked: Boolean!) {
  updateUserClaimStatusByPk(
    pkColumns: { claimId: $claimId, userId: $userId }
    _set: { hasMarked: $hasMarked }
  ) {
    hasMarked
    hasRead
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserClaimStatusByPkHasMarked', 'query')
  }
}