// plugins/graphql-subscription.ts
import { defineNuxtPlugin, useNuxtApp } from "#app";
import type { GetChannelParticipantsQuery } from "#gql";
import type { SubscribeEventsSubscription, SubscribeEventsSubscriptionVariables } from "#gql/default";

import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";

import SubscribeEventsDocument from "@/queries/events/streamEvents.gql";

type ClaimDataChanges = {
  id: string;
  claimStatus: string;
  claim?: boolean;
  origin?: boolean;
  fact?: boolean;
  source?: boolean;
  comment?: boolean;
  claimCategory?: boolean;
  user?: {
    id: string;
    username: string;
  } | null;
  users: string[];
};

type DataChanges = {
  claims: Record<string, ClaimDataChanges>;
  users: string[];
};

export default defineNuxtPlugin(() => {
  const userHasAccess = ref(false); // Placeholder for actual authentication state

  const { $ory, $logger } = useNuxtApp();
  const { subscribe } = useGqlSubscribtion();
  const queryClient = useQueryClient();
  broadcastQueryClient({
    queryClient,
    broadcastChannel: "faktenforum"
  });
  const { session } = $ory;
  const unsubscribe = ref<() => void>(() => {});

  watch(session, (newSession) => {
    $logger.debug("eventStream: Session updated", newSession);
    userHasAccess.value = newSession !== null && newSession.identity?.metadata_public?.role !== "aspirant";
    if (userHasAccess.value) {
      onUserLogin();
    } else {
      userLogout();
    }
  });

  function onError(error: unknown) {
    $logger.error("eventStream: Error received", error);
  }

  function aggregateEvents(data: SubscribeEventsSubscription) {
    const changesMap = data.eventStream.reduce<DataChanges>(
      (acc, event) => {
        $logger.debug("eventStream: Event received", event);
        if (event.tableName === TABLES.user) {
          acc.users.push(event.entryId);
        }

        const id = event.claimId as string;
        const change: ClaimDataChanges = {
          id,
          user: event.user,
          claimStatus: event.claimStatus,
          users: []
        };

        if (event.tableName === TABLES.fact) {
          change.fact = true;
        }

        if (event.tableName === TABLES.claim) {
          change.claim = true;
        }
        if (event.tableName === TABLES.origin) {
          change.origin = true;
        }
        if (event.tableName === TABLES.source) {
          change.source = true;
        }
        if (event.tableName === TABLES.comment_user_reactions) {
          change.comment = true;
        }
        if (event.tableName === TABLES.comment) {
          change.comment = true;
        }
        if (event.tableName === TABLES.claim_category) {
          change.claimCategory = true;
        }

        if (acc.claims[id]) {
          acc.claims[id] = { ...acc.claims[id], ...change };
        } else {
          acc.claims[id] = change;
        }
        return acc;
      },
      { claims: {}, users: [] }
    );
    return changesMap;
  }

  function invalidateRelatedCaches(changes: ClaimDataChanges[]) {
    let invalidateLists = false;
    changes.forEach((change) => {
      $logger.debug("eventStream: Invalidating cache", change);

      if (change.claim || change.origin || change.fact || change.source || change.claimCategory) {
        // Change single claim
        $logger.debug("eventStream: Invalidating single claim caches", change.id);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claim, change.id], exact: true });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.submission, change.id], exact: true });
      }
      if (isAcceptedClaim(change.claimStatus)) {
        if (change.claimCategory) {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claims], exact: true });
        }
      }
      if (change.claim || change.claimStatus || change.claimCategory) {
        invalidateLists = true;
      }

      if (change.comment) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.comments, change.id], exact: false });
        const channelParticipants = queryClient.getQueryData<
          GetChannelParticipantsQuery["comment"][number]["createdByUser"][]
        >([QUERY_KEYS.channelParticipants, change.id]);
        if (channelParticipants && change.user) {
          if (channelParticipants.find((user) => user.id === change.user?.id)) {
            //user already in list
            return;
          }
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.channelParticipants, change.id],
            exact: false
          });
        }
      }
      if (change.user) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.user, change.user.id], exact: true });
      }
    });
    if (changes.length > 0) {
      if (invalidateLists) {
        $logger.debug("eventStream: Invalidating list claim caches");
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claims] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.submissions] });
      }
    }
  }

  async function onUserLogin() {
    $logger.debug("eventStream: User logged in");
    const result = await GqlGetLastEventId();
    const lastEventId = result.event[0].id;

    const { unsubscribe: localUnsubscribe } = subscribe<
      SubscribeEventsSubscription,
      SubscribeEventsSubscriptionVariables
    >(
      SubscribeEventsDocument,
      { id: lastEventId, batchSize: 1000 },
      {
        onError,
        onNext: (data) => {
          $logger.debug("eventStream: Received data", data);
          const events = aggregateEvents(data);
          invalidateRelatedCaches(Object.values(events.claims));
          invalidateUserRelatedCaches(events.users);
        }
      }
    );
    unsubscribe.value = localUnsubscribe;
  }
  function userLogout() {
    $logger.debug("eventStream: User logged out");
    unsubscribe.value();
  }
  function invalidateUserRelatedCaches(users: string[]) {
    users.forEach((user) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.user, user], exact: true });
      $logger.warn("eventStream: Invalidating user related caches", user);
      $logger.warn("eventStream: Session", session.value);
      if (user === session.value.identity?.id) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.userAccountView], exact: true });
      }
      //  Todo invalidate all queries with user profile data (username etc...)
    });
  }
});
