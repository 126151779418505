import { defineNuxtPlugin } from "#app";
import type { AxiosError } from "axios";
import type { FormContext } from "vee-validate";
import type { MaybeRef } from "vue";
import { useRouter } from "vue-router";

import type { Session, UiContainer } from "@ory/client";
import { Configuration, FrontendApi } from "@ory/client";

export type ErrorType = "sdk400" | "sdk401" | "sdk403" | "sdk404" | "sdk410" | "sdk422" | "sdkFatal";
export type UseSdkErrorOptions = {
  form?: MaybeRef<FormContext | undefined>;
  defaultNav?: string;
  fatalToDash?: boolean;
  errorListener?: (type: ErrorType, message: string, error: AxiosError) => void;
  invalidInputListener?: (error: AxiosError) => void | Promise<void>;
};

export type ResponseErrorData = {
  error?: {
    id?: string;
  };
  redirect_browser_to?: string;
  use_flow_id?: string;
  ui?: UiContainer;
};

export type ResponseErrorHandler = (
  error: AxiosError<ResponseErrorData, unknown>
) => Promise<AxiosError | void>; // eslint-disable-line @typescript-eslint/no-invalid-void-type

export default defineNuxtPlugin((_) => {
  const { $logger } = useNuxtApp();
  const config = useRuntimeConfig();
  const router = useRouter();

  const sdk = new FrontendApi(
    new Configuration({
      basePath: config.public.kratosPublicUrl,
      baseOptions: {
        withCredentials: true
      }
    })
  );
  const session = ref<Session | null>(null);

  const useSdkError = (
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getFlow: ((flowId: string) => Promise<void | AxiosError>) | undefined,
    setFlow: ((flowData: unknown) => void) | undefined,
    options: UseSdkErrorOptions = {}
  ): ResponseErrorHandler => {
    const translateOryError = useTranslateOryErrors();
    const {
      form: formRef,
      defaultNav = "/",
      fatalToDash = false,
      errorListener,
      invalidInputListener
    } = options;
    const form = toValue(formRef);
    $logger.debug("useSdkError", defaultNav, fatalToDash, errorListener);
    const router = useRouter();

    // Vue doesn't have a useCallback hook, as its reactivity system makes it less necessary.
    // You can directly return the function.
    return async (error) => {
      const responseData = error.response?.data || {};

      switch (error.response?.status) {
        case 400:
          if (responseData.error?.id === "session_already_available") {
            console.warn("sdkError 400: `session_already_available`. Navigate to /");
            router.push("/");
            return Promise.resolve();
          }
          if (setFlow !== undefined) {
            console.warn("sdkError 400: update flow data");
            setFlow(responseData);
          }
          if (form && responseData?.ui?.nodes) {
            for (const node of responseData.ui.nodes) {
              if (node.attributes.node_type !== "input") {
                continue;
              }
              form.setFieldError(
                node.attributes.name,
                node.messages.filter((message) => message.type === "error").map(translateOryError)
              );
            }
          }
          await invalidInputListener?.(error);
          return Promise.resolve();
        case 401:
          console.warn("sdkError 401: Navigate to /login");

          router.push("/login");
          return Promise.resolve();
        case 403:
          // the user might have a session, but would require 2FA (Two-Factor Authentication)
          if (responseData.error?.id === "session_aal2_required") {
            router.replace("/login?aal2=true");
            return Promise.resolve();
          }

          if (responseData.error?.id === "session_refresh_required" && responseData.redirect_browser_to) {
            console.warn("sdkError 403: Redirect browser to");
            window.location.href = responseData.redirect_browser_to;
            return Promise.resolve();
          }
          break;
          break;
        case 404:
          console.warn("sdkError 404: Navigate to Error");
          if (defaultNav !== undefined) {
            router.push(
              `/error?error=${encodeURIComponent(
                JSON.stringify({
                  data: error.response?.data || error,
                  status: error.response?.status,
                  statusText: error.response?.statusText,
                  url: window.location.href
                })
              )}`
            );
            return Promise.resolve();
          }
          break;
        case 410:
          if (getFlow !== undefined && responseData.use_flow_id !== undefined) {
            console.warn("sdkError 410: Update flow");
            return getFlow(responseData.use_flow_id).catch((error) => {
              // Something went seriously wrong - log and redirect to defaultNav if possible
              console.error(error);
              errorListener?.("sdk410", "Error while updating flow", error);
              if (defaultNav !== undefined) {
                router.replace(defaultNav);
              } else {
                // Rethrow error when can't navigate and let caller handle
                throw error;
              }
            });
          } else if (defaultNav !== undefined) {
            console.warn("sdkError 410: Navigate to", defaultNav);
            errorListener?.("sdk410", "Error while updating flow", error);
            router.replace(defaultNav);
            return Promise.resolve();
          }
          break;
        case 422:
          if (responseData.redirect_browser_to !== undefined) {
            const currentUrl = new URL(window.location.href);
            const redirect = new URL(
              responseData.redirect_browser_to,
              // need to add the base url since the `redirect_browser_to` is a relative url with no hostname
              window.location.origin
            );

            // Path has changed
            if (currentUrl.pathname !== redirect.pathname) {
              console.warn("sdkError 422: Update path");
              // remove /ui prefix from the path in case it is present (not setup correctly inside the project config)
              // since this is an SPA we don't need to redirect to the Account Experience.
              redirect.pathname = redirect.pathname.replace("/ui", "");
              router.replace(redirect.pathname + redirect.search);
              return Promise.resolve();
            }

            // for webauthn we need to reload the flow
            const flowId = redirect.searchParams.get("flow");

            if (flowId != null && getFlow !== undefined) {
              // get new flow data based on the flow id in the redirect url
              console.warn("sdkError 422: Update flow");
              return getFlow(flowId).catch((error) => {
                // Something went seriously wrong - log and redirect to defaultNav if possible
                console.error(error);

                if (defaultNav !== undefined) {
                  router.replace(defaultNav);
                } else {
                  // Rethrow error when can't navigate and let caller handle
                  throw error;
                }
              });
            } else {
              console.warn("sdkError 422: Redirect browser to");
              window.location.href = responseData.redirect_browser_to;
              return Promise.resolve();
            }
          }
      }

      errorListener?.("sdkFatal", "Unhandled error", error);
      if (fatalToDash) {
        console.warn("sdkError: fatal error redirect to dashboard");
        router.push("/");
        return Promise.resolve();
      }

      throw error;
    };
  };

  async function logout() {
    try {
      // Create a "logout flow" in Ory Identities
      const { data: flow } = await sdk.createBrowserLogoutFlow();
      // Use the received token to "update" the flow and thus perform the logout
      await sdk.updateLogoutFlow({
        token: flow.logout_token
      });
      session.value = null;
      router.push("/login");
    } catch (error) {
      // The user could not be logged out
      // This typically happens if the token does not match the session,
      // or is otherwise malformed or missing
    }
  }

  return {
    provide: {
      ory: {
        sdk,
        useSdkError,
        logout,
        setSession: (newSession: Session) => {
          session.value = newSession;
        },
        session: computed(() => session.value as Session), // Read-only access to session
        loggedIn: computed(() => session.value !== null) // Read-only access to loggedIn status
      }
    }
  };
});
