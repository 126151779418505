<script lang="ts" setup>
const props = defineProps<{
  class?: string;
}>();
const colorMode = useColorMode();
</script>

<template>
  <ClientOnly>
    <img
      :src="colorMode.value === 'dark' ? '/logo-complete-dark.svg' : '/logo-complete-light.svg'"
      alt="Logo"
      :class="cn('w-full', props.class)"
    />
  </ClientOnly>
</template>
