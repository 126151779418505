import { defineStore } from "pinia";

export const useLightboxStore = defineStore("lightbox", {
  state: () => ({
    visible: false,
    src: ""
  }),
  actions: {
    setupLightbox(src?: string) {
      if (!src) return;
      this.visible = true;
      this.src = src;
    }
  }
});
