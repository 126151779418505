import type { UiText } from "@ory/client";

export default function useTranslateOryErrors() {
  const { t } = useI18n();
  function translateOryError(message: UiText) {
    switch (message.id) {
      case 4000034:
        return t("kratos.passwordInDataBreach.description");

      default:
        return message.text;
    }
  }
  return translateOryError;
}
