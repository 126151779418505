import type * as sdk from "matrix-js-sdk";

function extractUsernameFromAlias(alias: string | undefined | null) {
  if (!alias) return null;
  return alias.split(":")[0].slice(1);
}
export function upgradePlainTextToEditorContent(content: string) {
  try {
    const result = JSON.parse(content);
    if (typeof result !== "object") {
      console.log("chat upgradePlainTextToEditorContent found no an object", result);
      throw new Error("Invalid content");
    }
    return content;
  } catch (_) {
    console.log("chat upgradePlainTextToEditorContent error", _);
    return `{"type":"doc","content":[{"type":"paragraph","content":[${JSON.stringify({ type: "text", text: content })}]}]}`;
  }
}

// threadsMessageCount threads.value[event.getId() as string]?.length ?? 0
export function messageEventToComment(event: sdk.MatrixEvent, threadsMessageCount: number) {
  const messageBody = event.getContent().body;

  const comment = {
    id: event.getId(),
    createdByUser: {
      id: event.getSender(),
      username: extractUsernameFromAlias(event.getSender()) ?? ""
    },
    createdAt: event.getDate()?.toISOString() ?? "",
    // updatedAt: event.getTimestamp(),
    content: upgradePlainTextToEditorContent(messageBody ? messageBody : "deleted"),
    deleted: !messageBody,
    blocked: false,
    userReactions: [],
    threadAggregate: {
      aggregate: {
        count: threadsMessageCount
      }
    }
  };
  return comment;
}

export function messageEventToReaction(event: Partial<sdk.IEvent>) {
  const reaction = {
    id: event.event_id,
    emoji: event.content?.["m.relates_to"]?.key ?? "",
    createdAt: new Date().toISOString(),
    user: {
      id: "",
      username: extractUsernameFromAlias(event.sender) ?? ""
    }
  };
  return reaction;
}

export function channelMemberToParticipant(member: sdk.RoomMember) {
  return {
    id: member.name,
    username: extractUsernameFromAlias(member.name) ?? ""
  };
}

export function nameToChannel(name: string | null | undefined, chatBaseUrl: string) {
  if (!name) return null;
  return `#ff-channel-${name}:${chatBaseUrl.split("://")[1]}`;
}

export async function getJWToken(apiBase: string): Promise<{ token: string }> {
  const jwtUrl = new URL("/api/v1/chat/token", apiBase);

  const response = await fetch(jwtUrl);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data;
}
