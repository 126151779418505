import type { FilterSubmissionQuery } from "#gql";

type Submission = FilterSubmissionQuery["data"][0];

export function getFirstOriginImage(origins: Submission["origins"]) {
  const file = getFirstDefinedField(origins, "file");
  return file
    ? {
        src: `/api/v1/files/${file.id}`,
        mimeType: file.mimeType
      }
    : undefined;
}

export function isSubmission(claimStatus: string) {
  return SUBMISSION_STATUSES.includes(claimStatus as ALL_CLAIM_STATUSES);
}
export function isAcceptedClaim(claimStatus: string) {
  return !isSubmission(claimStatus);
}
