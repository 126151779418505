<script lang="ts" setup>
const store = useLightboxStore();
const { beforeEach } = useRouter();
beforeEach(() => {
  store.visible = false;
});
</script>

<template>
  <VueEasyLightbox :visible="store.visible" :imgs="[store.src]" @hide="store.visible = false" />
</template>
