<script lang="ts" setup></script>

<template>
  <div class="fixed z-[99999] uppercase bottom-0 right-0 m-1 px-2 text-sm text-neutral-8 bg-neutral-0">
    <div class="max-xs:hidden sm:hidden">xs</div>
    <div class="max-sm:hidden md:hidden">sm</div>
    <div class="max-md:hidden lg:hidden">md</div>
    <div class="max-lg:hidden xl:hidden">lg</div>
    <div class="max-xl:hidden 2xl:hidden">xl</div>
    <div class="max-2xl:hidden 3xl:hidden">2xl</div>
    <div class="max-3xl:hidden">3xl</div>
  </div>
</template>
