import { nanoid } from "nanoid";
import { defineStore } from "pinia";

type TToastType = "error" | "warning" | "info" | "success";

export type TToast = {
  id: string;
  title?: string;
  description?: string;
  type?: TToastType;
  sensitivity?: "foreground" | "background";
  duration?: number;
};
const TOAST_LIMIT = 100;

export const useToastsStore = defineStore("toasts", {
  state: () => ({
    toasts: [] as TToast[]
  }),
  actions: {
    add(toast: { title?: string; description?: string; type?: TToastType }) {
      const toastComplete = {
        id: nanoid(),
        ...toast
      };
      this.toasts = [toastComplete, ...this.toasts].slice(0, TOAST_LIMIT);
      return {
        id: toastComplete.id
      };
    },
    update(toast: TToast) {
      this.toasts = this.toasts.map((t) => (t.id === toast.id ? { ...t, ...toast } : t));
    },
    remove(toastId?: string) {
      if (toastId === undefined) this.toasts = [];
      else this.toasts = this.toasts.filter((t) => t.id !== toastId);
    }
  }
});
