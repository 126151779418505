<script setup></script>

<template>
  <div class="md:hidden fixed z-[100] bg-neutral-1 flex flex-col items-center justify-center inset-0">
    <Logo class="w-[40vw]" />
    <div class="p-4 text-center flex flex-col gap-5 items-center">
      <h2 class="h0 text-balance">
        Das Faktenforum ist derzeit nur für die Benutzung auf größeren Geräten ausgelegt.
      </h2>
      <p class="base text-balance max-w-80">
        Die Beta-Version unterstützt nur Bildschirme ab 768 Pixel Breite. Eine Erweiterung auf Mobilgeräte ist
        in Planung.
      </p>
    </div>
  </div>
</template>
