// plugins/matrix.js
import { defineNuxtPlugin } from "#app";
import * as sdk from "matrix-js-sdk";

import type { Session } from "@ory/client";

import type { Comment, Participant } from "~/types";

// Polyfill for the global object
if (typeof global === "undefined") {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).global = window;
}

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  // Initialize the Matrix client
  const matrixClient = sdk.createClient({
    baseUrl: config.public.chatBaseUrl // Replace with your Matrix server URL
  });

  // start the client to setup the connection to the server

  const { $ory, $logger } = useNuxtApp();
  const EVENT_PAGE_SIZE = 30;
  // Login part
  const { session } = $ory;
  const matrixSession = ref<sdk.LoginResponse | undefined>(undefined);

  async function onUserLogin() {
    try {
      const { token } = await getJWToken(config.public.apiBase);
      $logger.debug("chat: token", token);
      const response = await matrixClient.login("org.matrix.login.jwt", {
        token
      });
      matrixSession.value = response;
      $logger.debug("chat: User logged into the matrix", response);

      matrixClient.startClient({ initialSyncLimit: EVENT_PAGE_SIZE });

      // Listen for the sync event
      matrixClient.on(sdk.ClientEvent.Sync, (state: sdk.SyncState) => {
        if (state === "PREPARED") {
          $logger.debug("Matrix client is synced and ready.");
          acceptOpenInvitesToSpaces();
        }
      });
    } catch (error) {
      $logger.error("chat: Login failed", error);
    }
  }

  function userLogout() {
    $logger.debug("chat: User logged out of the matrix");
  }

  watch(session, (newSession: Session) => {
    $logger.debug("chat: Session updated", newSession);
    if (newSession !== null) {
      onUserLogin();
    } else {
      userLogout();
    }
  });

  // Listen for membership changes and accept invites to spaces

  function acceptOpenInvitesToSpaces() {
    const rooms = matrixClient.getRooms();
    const invites = rooms.filter((room) => {
      const membership = room.getMyMembership();
      const isSpace = room.getType() === "m.space"; // Check if the room is a space
      return membership === "invite" && isSpace;
    });

    invites.forEach((invite) => {
      $logger.debug(`Invite to space: ${invite.roomId}, Name: ${invite.name}`);
      // Automatically join the space
      matrixClient
        .joinRoom(invite.roomId)
        .then(() => {
          $logger.debug(`Joined space: ${invite.roomId}`);
        })
        .catch((error) => {
          console.error(`Failed to join space: ${invite.roomId}`, error);
        });
    });

    if (invites.length === 0) {
      $logger.debug("No open invites to spaces.");
    }
  }
  matrixClient.on(sdk.RoomMemberEvent.Membership, (event, member) => {
    if (member.membership === "invite" && member.userId === matrixClient.getUserId()) {
      const roomId = member.roomId;
      const room = matrixClient.getRoom(roomId);
      const isSpace = room?.getType() === "m.space"; // Check if the room is a space

      $logger.debug(`Received invite to room: ${roomId}`);

      if (isSpace) {
        // Automatically join the space
        matrixClient
          .joinRoom(roomId)
          .then(() => {
            $logger.debug(`Joined space: ${roomId}`);
          })
          .catch((error) => {
            console.error(`Failed to join space: ${roomId}`, error);
          });
      } else {
        // TODO: Notify user about the invite to a non-space room
        $logger.debug(`Invite to non-space room: ${roomId}`);
      }
    }
  });

  // rooom Event Handline
  const { addEvent, getEvent, updateEventId, addComment, getComment, reset } = useEventMap();
  const isLoadingPage = ref(false);
  const roomName = ref<string | null>(null);
  const threadId = ref<string | null>(null);
  const roomAlias = computed(() => nameToChannel(roomName.value, config.public.chatBaseUrl));
  const error = ref<string | null>(null);
  const comments = ref<Comment[]>([]);
  const participants = ref<Participant[]>([]);
  const currentRoom = ref<sdk.Room | null>(null);
  const threadComments = ref<Comment[]>([]);
  const threadRootComment = ref<Comment | null>(null);
  const threads = ref<Record<string, Comment[]>>({});
  const canPaginate = ref(false);

  function handleMessageEvent(event: sdk.MatrixEvent) {
    $logger.debug(
      `chat render event as comment is threadComment ${event.isRelation("m.thread")}  ` + event.getId()
    );
    $logger.debug("handleMessageEvent Hello Message comment", toPlainClone(event));
    if (event.getContent().body) {
      if (event.getRelation()?.event_id && event.getRelation()?.rel_type === "m.replace") {
        $logger.debug("handleMessageEvent found update event", event.getRelation()?.event_id);
        const comment = getComment(event.getRelation()!.event_id!);
        comment.updatedAt = event.getDate()?.toISOString() ?? "";
        $logger.debug("handleMessageEvent updated content:", toPlainClone(event.getContent().body));
        comment.content = upgradePlainTextToEditorContent(event.getContent()["m.new_content"].body);
        console.log(comment.content);
        return;
      }
      const comment = messageEventToComment(event, threads.value[event.getId() as string]?.length ?? 0);
      addComment(comment);
      if (event.isRelation("m.thread")) {
        const parentEventId = event.getRelation()?.event_id;
        if (parentEventId) {
          const rootComment = getComment(parentEventId);
          if (rootComment) {
            rootComment.threadAggregate!.aggregate!.count += 1;
          }
          if (threads.value[parentEventId]) {
            threads.value[parentEventId].push(comment);
          } else {
            threads.value[parentEventId] = [comment];
          }
        }
        return;
      } else {
        comments.value.push(comment);
      }
    }
  }

  function handleReactionEvent(event: Partial<sdk.IEvent>) {
    const commentId = event.content?.["m.relates_to"]?.event_id;
    const comment = getComment(commentId!);
    if (comment && event.event_id) {
      const reaction = messageEventToReaction(event);
      comment.userReactions.push(reaction);
    }
  }

  function handleRedactionEvent(event: sdk.MatrixEvent & { event: { redacts: string } }) {
    const redactedEvent = getEvent(event.event.redacts);
    if (!redactedEvent) {
      return;
    }

    $logger.debug("chat.handleRedactionEvent found redactedEvent", toPlainClone(redactedEvent));

    if (redactedEvent.getType() === "m.room.message") {
      $logger.debug("chat.handleRedactionEvent found message top redact event", redactedEvent.getId());
      //TODO: handle comment redact event
      return;
    }

    if (redactedEvent.getType() === "m.reaction" && redactedEvent.getRelation()) {
      $logger.debug("chat.handleRedactionEvent found reaction redaction event", redactedEvent.getId());

      const comment = getComment(redactedEvent.getRelation()!.event_id!);
      $logger.debug("chat.handleRedactionEvent found reaction comment", toPlainClone(comment));
      comment.userReactions = comment?.userReactions.filter(
        (reaction) => reaction.id !== redactedEvent.getId()
      );

      return;
    }
  }
  function handleRoomEvent(event: sdk.MatrixEvent) {
    $logger.debug("chat room event " + event.getType(), event);
    addEvent(event);
    if (event.getType() === "m.room.message") {
      handleMessageEvent(event);
      return;
    }
    if (event.getType() === "m.reaction") {
      handleReactionEvent(event.event);
      return;
    }
    if (event.getType() === "m.room.redaction") {
      handleRedactionEvent(event);
      return;
    }
  }

  // local temp Id updating on event resolving
  matrixClient.on(sdk.ClientEvent.Room, (room) => {
    room.on(
      sdk.RoomEvent.LocalEchoUpdated,
      (event: sdk.MatrixEvent, room: sdk.Room, oldEventId?: string, _?: null | string) => {
        if (
          oldEventId &&
          event.event.event_id &&
          event.event.event_id !== oldEventId &&
          event.status === sdk.EventStatus.SENT
        ) {
          updateEventId(event.event.event_id, oldEventId);
        }
      }
    );

    room.on(sdk.RoomEvent.Timeline, (event, room, toStartOfTimeline) => {
      if (toStartOfTimeline) return; // Ignore events from the start of the timeline
      handleRoomEvent(event);
    });
  });

  // Listen for typing changes
  matrixClient.on(sdk.RoomMemberEvent.Typing, function (event, member) {
    if (member.typing) {
      $logger.debug(member.name + " is typing...");
    } else {
      $logger.debug(member.name + " stopped typing.");
    }
  });

  // Handline Room Changes
  watch(roomName, async (newRoomName, oldRoomName) => {
    if (newRoomName) {
      try {
        isLoadingPage.value = true;
        // Check if room is already joined if not join the room

        let room = matrixClient.getRoom(roomAlias.value!);

        if (room && room.getMyMembership() === "join") {
          $logger.debug(`Already joined room: ${newRoomName}`);
          isLoadingPage.value = false;
          return;
        } else {
          room = await matrixClient.joinRoom(roomAlias.value!);
        }
        if (!room) {
          $logger.error("chat room not found", roomAlias.value);
          return;
        }
        comments.value = [];
        threadComments.value = [];
        reset();
        threads.value = {};

        $logger.debug(room.getLiveTimeline().getEvents());
        const timeline = room.getLiveTimeline();
        const events = timeline.getEvents();

        canPaginate.value = await matrixClient.paginateEventTimeline(timeline, {
          backwards: true,
          limit: 50
        });

        events.forEach((event) => handleRoomEvent(event));
        participants.value = room.getMembers().map(channelMemberToParticipant);
        $logger.debug("chat participants", participants.value);
        currentRoom.value = room;
        isLoadingPage.value = false;
        if (threadId.value) {
          // after loading content update thread contents
          switchToThread(threadId.value);
        }
      } catch (joinError) {
        console.error("Failed to join room", joinError);
        isLoadingPage.value = false;
        roomName.value = oldRoomName;
        error.value = joinError as string;
        return;
      }
    } else {
      isLoadingPage.value = false;
      comments.value = [];
      participants.value = [];
    }
    error.value = null;
  });

  // exported Plugin functions

  function switchToRoom(name: string) {
    roomName.value = name;
  }
  function switchToThread(commentId: string) {
    $logger.debug("chat switch to threadId", commentId);
    threadId.value = commentId;
    threadComments.value = [];
    threadRootComment.value = getComment(commentId);
    $logger.debug("chat switch to threadRoot", threadRootComment.value);
    threadComments.value = threads.value[commentId] ?? [];
  }

  async function sendMessage(message: string) {
    $logger.debug(`Send message ${message} to ${roomAlias.value}`);
    matrixClient.sendEvent(currentRoom.value!.roomId, "m.room.message" as keyof sdk.TimelineEvents, {
      body: message,
      msgtype: "m.text"
    });
  }
  async function sendThreadMessage(message: string) {
    matrixClient.sendEvent(
      currentRoom.value!.roomId,
      threadId.value! as string,
      "m.room.message" as keyof sdk.TimelineEvents, // Cast to the correct type
      {
        body: message,
        msgtype: "m.text"
      }
    );
  }

  function updateMessage(commentId: string, content: string) {
    const eventContent = {
      body: "* " + content,
      msgtype: "m.text",
      "m.new_content": {
        body: content,
        msgtype: "m.text"
      },
      "m.relates_to": {
        rel_type: "m.replace",
        event_id: commentId
      }
    };

    matrixClient.sendEvent(
      currentRoom.value!.roomId,
      "m.room.message" as keyof sdk.TimelineEvents,
      eventContent
    );
  }

  async function reactToComment(commentId: string, emoji: string) {
    //check if reaction allready exists, if so remove it first
    const comment = getComment(commentId);
    $logger.debug(`Chat reaction ${emoji} to comment ${commentId}`, toPlainClone(comment));
    const reaction = comment.userReactions.find(
      (reaction) => reaction.emoji === emoji && reaction.user.username === matrixClient.getUserIdLocalpart()
    );

    if (reaction) {
      return await removeReaction(reaction.id);
    }

    try {
      const event = {
        "m.relates_to": {
          rel_type: "m.annotation" as sdk.RelationType.Annotation, // Explicitly set to Annotation
          event_id: commentId,
          key: emoji
        }
      };
      const isSendEventResponse = await matrixClient.sendEvent(
        currentRoom.value!.roomId,
        sdk.EventType.Reaction,
        event
      );

      $logger.debug("chat send reaction", isSendEventResponse.event_id);
    } catch (error) {
      console.error(`Failed to react to comment ${commentId}`, error);
    }
  }

  async function removeReaction(reactionEventId: string) {
    try {
      $logger.debug(
        `Remove reaction with event ID ${reactionEventId} from room ${currentRoom.value!.roomId}`
      );
      await matrixClient.redactEvent(currentRoom.value!.roomId, reactionEventId);
    } catch (error) {
      console.error(`Failed to remove reaction with event ID ${reactionEventId}`, error);
    }
  }

  async function getNextPage() {
    if (!currentRoom.value) {
      $logger.error("chat getNextPage: currentRoom not found");
      return;
    }
    $logger.debug("chat getMoreEvents");
    isLoadingPage.value = true;
    try {
      canPaginate.value = await matrixClient.paginateEventTimeline(currentRoom.value!.getLiveTimeline(), {
        backwards: true,
        limit: EVENT_PAGE_SIZE
      });
    } catch (error) {
      //TODO: Error handling
      console.error("Failed to get next page", error);
    } finally {
      isLoadingPage.value = false;
    }
  }

  return {
    provide: {
      chat: {
        matrixClient,
        nameToChannel,
        switchToRoom,
        isLoadingPage,
        comments,
        participants,
        switchToThread,
        threadComments,
        threadRootComment,
        sendMessage,
        updateMessage,
        sendThreadMessage,
        reactToComment,
        removeReaction,
        getNextPage,
        canPaginate
      }
    }
  };
});
