subscription subscribeEvents($id: bigint = 0, $batchSize: Int = 10) {
  eventStream(batchSize: $batchSize, cursor: { initialValue: { id: $id } }) {
    action
    claimId
    claimStatus
    createdAt
    entryId
    id
    tableName
    user {
      id
      username
    }
  }
}
