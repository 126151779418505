/* eslint-disable @typescript-eslint/no-dynamic-delete */
import type * as sdk from "matrix-js-sdk";

import type { Comment } from "~/types";

export default function useEventMap() {
  const { $logger } = useNuxtApp();

  const state = ref({
    eventIdCommentsMap: {} as Record<string, Comment>,
    eventIdEventMap: {} as Record<string, sdk.MatrixEvent>
  });

  function addEvent(event: sdk.MatrixEvent) {
    const eventId = event.getId();
    if (!eventId) {
      $logger.warn("useEventMap: Event has no Id will not add it map", { event });
      return;
    }
    state.value.eventIdEventMap[eventId] = event;
  }

  function updateEventId(newEventId: string, oldEventId: string) {
    $logger.debug("useEventMap: updateEventId", toPlainClone({ newEventId, oldEventId }));
    const event = state.value.eventIdEventMap[oldEventId];
    if (event) {
      state.value.eventIdEventMap[newEventId] = event;
      delete state.value.eventIdEventMap[oldEventId];

      // check if event is and reaction event
      if (event.getType() === "m.reaction") {
        $logger.debug("useEventMap: updateEventId found reaction event", toPlainClone(event.event));
        const commentId = event.event.content!["m.relates_to"]?.event_id;
        const comment = state.value.eventIdCommentsMap[commentId!];
        // find Reactions in comments
        $logger.debug("useEventMap: updateEventId found reaction in comment", toPlainClone(comment));
        const reaction = comment?.userReactions.find((reaction) => reaction.id === oldEventId);
        if (reaction) {
          $logger.debug("useEventMap: updateEventId found reaction in comment", toPlainClone(reaction));
          reaction.id = newEventId;
        } else {
          $logger.warn(
            `useEventMap: Reaction with id ${oldEventId} not found in comment`,
            toPlainClone(comment)
          );
        }
        return;
      }

      // check if it is an comment Event
      if (event.getType() === "m.room.message") {
        const comment = state.value.eventIdCommentsMap[oldEventId];
        $logger.debug("useEventMap: updateEventId found comment event", { event, comment });
        if (comment) {
          comment.id = newEventId;
          state.value.eventIdCommentsMap[newEventId] = comment;
          delete state.value.eventIdCommentsMap[oldEventId];
          return;
        }
      }
    }
  }

  function addComment(comment: Comment) {
    state.value.eventIdCommentsMap[comment.id] = comment;
  }

  function getComment(eventId: string) {
    return state.value.eventIdCommentsMap[eventId];
  }

  function reset() {
    state.value = {
      eventIdCommentsMap: {},
      eventIdEventMap: {}
    };
  }

  function getEvent(eventId: string) {
    return state.value.eventIdEventMap[eventId];
  }

  return {
    state,
    reset,
    addEvent,
    getEvent,
    updateEventId,
    addComment,
    getComment
  };
}
